<template>
  <div>
    <div class="top" style="display: flex; margin-bottom: 20px">
      <div class="ml">
        <span style="margin-right: 10px">下单时间</span>

        <el-date-picker
          :disabled="!search"
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <div class="ml">
        <span style="margin: 0 10px 0px 30px">场地名称</span>
        <el-select v-model="address_id" filterable placeholder="全部" :disabled="!search">
          <el-option
            v-for="item in address_list"
            :key="item.address_id"
            :label="item.address_name"
            :value="item.address_id"
          ></el-option>
        </el-select>
      </div>

      <el-button type="primary" style="margin-left: 10px" @click="searcht2()" v-if="search">查询</el-button>
      <el-button type="warning" style="margin-left: 30px" @click="tuisearch2()" v-else>取消搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%; max-height: 65vh; overflow-y: scroll">
      <el-table-column prop="address_name" label="场地名称"></el-table-column>
      <el-table-column prop="" label="电动车充停">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count6 }}</div>
          <div>套餐数: {{ scope.row.package_count6 }}</div>
          <div>金额: {{ scope.row.cache_sum6 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="电动车单停">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count1 }}</div>
          <div>套餐数: {{ scope.row.package_count1 }}</div>
          <div>金额: {{ scope.row.cache_sum1 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="电动车单充">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count4 }}</div>
          <div>套餐数: {{ scope.row.package_count4 }}</div>
          <div>金额: {{ scope.row.cache_sum4 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="自行车单停">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count2 }}</div>
          <div>套餐数: {{ scope.row.package_count2 }}</div>
          <div>金额: {{ scope.row.cache_sum2 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="三轮车充停">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count7 }}</div>
          <div>套餐数: {{ scope.row.package_count7 }}</div>
          <div>金额: {{ scope.row.cache_sum7 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="三轮车单充">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count5 }}</div>
          <div>套餐数: {{ scope.row.package_count5 }}</div>
          <div>金额: {{ scope.row.cache_sum5 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="三轮车单停">
        <template #default="scope">
          <div>用户数: {{ scope.row.address_user_count3 }}</div>
          <div>套餐数: {{ scope.row.package_count3 }}</div>
          <div>金额: {{ scope.row.cache_sum3 }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { addrCount } from "../../request/api";
import Moment from "moment";
import { number } from "echarts";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      address_list: [],
      search: true,
      datevalue: ["", ""],
      address_id: "",
      tableData: [],
    };
  },
  async created() {
    this.datevalue = this.$route.query.datevalue ? this.$route.query.datevalue.split(",") : "";
    this.address_id = this.$route.query.addressid ? Number(this.$route.query.addressid) : "";
    console.log(this.datevalue || this.address_id, this.datevalue, this.address_id);
    if (this.datevalue[0] || this.datevalue[1] || this.address_id) {
      this.search = false;
    }
    this.taocandimens();
  },
  watch: {
    page() {
      this.taocandimens();
    },
    pagepage() {
      this.taocandimens();
    },
  },
  methods: {
    gg(e) {
      console.log(e, "ee");
    },
    searcht2() {
      this.taocandimens();
      this.search = false;
    },
    tuisearch2() {
      this.datevalue = "";
      this.address_id = "";
      this.taocandimens();
      this.search = true;
    },

    taocandimens() {
      return new Promise((resolve, reject) => {
        addrCount({
          page: this.page,
          size: this.pagepage,
          start_time: this.datevalue[0] ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          end_time: this.datevalue[1] ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss") : "",
          address_id: this.address_id,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.list;
          this.address_list = res.list.address_list;
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.userexamine {
  /deep/.cell {
    text-align: center;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
